import theme from "./theme";

export const KLIPS_COLOURS = {
  paragraph: {
    color: theme.color.blue600,
  },
  heading: {
    color: theme.color.blue600,
  },
  link: {
    color: theme.color.blue400,
  },
}
